import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout/Layout';
import { slugify } from '../utils/Misc';
const faqs = [
  {
    title: 'What is an Event Organiser? what they do? ',
    content:
      'An organiser will host a tournament, they will have a system to control and manage the tournament, they can hire streamers, on this panel E-Warz will also have a sponsor section as well, which can show interest in any tournament, this if both parties agree sponsors can financially support any tournament.',
  },
  {
    title: 'How do I find or join a tournament?',
    content:
      'The players or team leader or manager can create the team in his platform, they can look for any tournament where the team can take part in, once they have signed up for the tournament, the organisers can schedule the matches and the information will be passed to the member who will be part of that match.',
  },
  {
    title: 'Who is a Team Manger?',
    content:
      'A team manager/ Team leader will have a overview on all tournaments they have took part in, they will see how they are doing as team, what are the future tournaments they can take party in, also by seeming the recent matches they can make important discussions on the team and the are which needs improvement.',
  },
  {
    title: 'What is a Commentator and what do they do?',
    content:
      'A commentator will be the online state host in other words this person will be sports caster who provides commentary during a live tournament event, commentator will usually work either the streamer in synchronisation to deliver the best experience to the audience.',
  },
];
const PrivacyPolicy = () => {
  return (
    <Layout>
      <Helmet title="Privacy Policy | E-Warz Tournaments" />
      <section className="clean-block features bg-dark-alt text-white pb-lg-5">
        <div className="container">
          <div className="block-heading">
            <h2 className="text-info">Privacy Policy</h2>
          </div>
          <div>
            <p class="c0">
              <span class="c2">
                Ewarz.org website is owned by IYG Entertainment Private Limited , a data controller
                of your personal data.
              </span>
            </p>
            <p class="c0">
              <span class="c2">
                We have adopted this Privacy Policy, which determines how we are processing the
                information collected by Ewarz.org, which also provides the reasons why we must
                collect certain personal data about you. Therefore, you must read this Privacy
                Policy before using the Ewarz.org website.
              </span>
            </p>
            <p class="c0">
              <span class="c2">
                We take care of your personal data and undertake to guarantee its confidentiality
                and security.
              </span>
            </p>
            <h4 class="c0" id="h.dq6havpla9kt">
              <span class="c1">Personal information we collect:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                When you visit Ewarz.org, we automatically collect certain information about your
                device, including information about your web browser, IP address, time zone, and
                some of the installed cookies on your device. Additionally, as you browse the Site,
                we collect information about the individual web pages or products you view, what
                websites or search terms referred you to the Site, and how you interact with the
                Site. We refer to this automatically-collected information as &ldquo;Device
                Information.&rdquo; Moreover, we might collect the personal data you provide to us
                (including but not limited to Name, Surname, Address, payment information, etc.)
                during registration to be able to fulfil the agreement.
              </span>
            </p>
            <h4 class="c0" id="h.2mnme9tpmsrb">
              <span class="c1">Why do we process your data?</span>
            </h4>
            <p class="c0">
              <span class="c2">
                Our top priority is customer data security, and, as such, we may process only
                minimal user data, only as much as it is absolutely necessary to maintain the
                website. Information collected automatically is used only to identify potential
                cases of abuse and establish statistical information regarding website usage. This
                statistical information is not otherwise aggregated in such a way that it would
                identify any particular user of the system.
              </span>
            </p>
            <p class="c0">
              <span class="c5">
                You can visit the website without telling us who you are or revealing any
                information, by which someone could identify you as a specific, identifiable
                individual. If, however, you wish to use some of the website&rsquo;s features, or
                you wish to receive our newsletter or provide other details by filling out a form,
                you may provide personal data to us, such as your email, first name, last name, city
                of residence, organization, telephone number. You can choose not to provide us with
                your personal data, but then you may not be able to take advantage of some of the
                website&rsquo;s features. For example, you won&rsquo;t be able to receive our
                Newsletter or contact us directly from the website. Users who are uncertain about
                what information is mandatory are welcome to contact us via{' '}
              </span>
              <span class="c4">
                <a class="c7" href="mailto:support@ewarz.freshdesk.com">
                  support@ewarz.freshdesk.com
                </a>
              </span>
              <span class="c2">.</span>
            </p>
            <p class="c0 c10">
              <span class="c2"></span>
            </p>
            <p class="c0 c10">
              <span class="c2"></span>
            </p>
            <p class="c0 c10">
              <span class="c2"></span>
            </p>
            <h4 class="c0" id="h.o3h37k9kt8pe">
              <span class="c1">Your rights:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                If you are a European resident, you have the following rights related to your
                personal data:
              </span>
            </p>
            <ul class="c12 lst-kix_ru92li7l4ba1-0 start">
              <li class="c3 li-bullet-0">
                <span class="c2">The right to be informed.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right of access.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right to rectification.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right to erasure.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right to restrict processing.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right to data portability.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">The right to object.</span>
              </li>
              <li class="c3 li-bullet-0">
                <span class="c2">
                  Rights in relation to automated decision-making and profiling.
                </span>
              </li>
            </ul>
            <p class="c0">
              <span class="c2">
                If you want to exercise this right, please contact us through the contact
                information below.
              </span>
            </p>
            <p class="c0">
              <span class="c2">
                Additionally, suppose you are a European resident. In that case, we note that we are
                processing your information in order to fulfil contracts we might have with you (for
                example, if you make an order through the Site), or otherwise to pursue our
                legitimate business interests listed above. Additionally, please note that your
                information might be transferred outside of Europe, including Canada and the United
                States.
              </span>
            </p>
            <h4 class="c0" id="h.c4xx5yfb6vrv">
              <span class="c1">Links to other websites:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                Our website may contain links to other websites that are not owned or controlled by
                us. Please be aware that we are not responsible for such other websites or third
                parties privacy practices. We encourage you to be aware when you leave our website
                and read the privacy statements of each website that may collect personal
                information.
              </span>
            </p>
            <h4 class="c0" id="h.56grg5migfx4">
              <span class="c1">Information security:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                We secure information you provide on computer servers in a controlled, secure
                environment, protected from unauthorized access, use, or disclosure. We keep
                reasonable administrative, technical, and physical safeguards to protect against
                unauthorized access, use, modification, and personal data disclosure in its control
                and custody. However, no data transmission over the Internet or wireless network can
                be guaranteed.
              </span>
            </p>
            <h4 class="c0" id="h.u27j5ihspxmz">
              <span class="c1">Legal disclosure:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                We will disclose any information we collect, use or receive if required or permitted
                by law, such as to comply with a subpoena or similar legal process, and when we
                believe in good faith that disclosure is necessary to protect our rights, protect
                your safety or the safety of others, investigate fraud, or respond to a government
                request.
              </span>
            </p>
            <h4 class="c0" id="h.ugg8yy2u3tj1">
              <span class="c1">Contact information:</span>
            </h4>
            <p class="c0">
              <span class="c2">
                If you would like to contact us to understand more about this Policy or wish to
                contact us concerning any matter relating to individual rights and your Personal
                Information, you may send an email to support@ewarz.freshdesk.com.
              </span>
            </p>
            <p class="c9">
              <span class="c6"></span>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PrivacyPolicy;
